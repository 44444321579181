import React from 'react'
import { graphql } from 'gatsby'

// Components
import Layout from '../layouts/index'
import Seo from '../components/seo'
import Wrapper from '../components/wrapper'
import HowHero from '../components/how_we_work/how_hero'
import HowMethod from '../components/how_we_work/how_method'
import Next from '../components/next'

const HowWeWork = ({ data, path, pageContext }) => {

  return (
    <Layout path={path} locale={pageContext.locale} absolutePath={pageContext.absolutePath}>
      <Seo
        slug={data.datoCmsHowWeWork.slug.link}
        content={data.datoCmsHowWeWork.seo}
        lang={pageContext.locale}
        publication={data.datoCmsHowWeWork.meta}
        keywords={data.datoCmsHowWeWork.methods.map((item) => item.title).join(', ')}
      />
      <React.Fragment>
        <Wrapper color="yellow">
          <HowHero content={data.datoCmsHowWeWork}/>
        </Wrapper>
        <Wrapper color="black">
          <Wrapper grid>
            {data.datoCmsHowWeWork.methods.map((item, index) => (
              // Methods
              <HowMethod data-id={item.id}
                         key={`method-${index}`}
                         content={item}/>
            ))}
          </Wrapper>
        </Wrapper>
        <Wrapper color="gradient">
          <Wrapper grid>
            <div className="how__next row no-padding">
              {data.datoCmsHowWeWork.nextSection.map((item, index) => (
                // Nexts
                <Next data-id={item.id}
                      key={`next-${index}`}
                      content={item}
                      yellow={index <= 0}/>
              ))}
            </div>
          </Wrapper>
        </Wrapper>
      </React.Fragment>
    </Layout>
  )
}

export default HowWeWork

export const query = graphql`
  query HowWeWorkQuery($locale: String!) {
    datoCmsHowWeWork(locale: {eq: $locale}) {
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      seo {
        title
        description
        image {
          url
          height
          width
        }
        twitterCard
      }
      headline
      headlineSeo
      paragraph
      textLink
      slug {
        link
      }
      methods {
        id
        step
        title
        paragraph
        icon {
          url
        }
      }
      nextSection {
        id
        title
        textLink
        link {
          link
          locale
        }
      }
    }
  }
`
