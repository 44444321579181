import React from 'react'
import { Link } from 'gatsby'

// Assets
import MnlArrow from '../assets/images/MnlArrow'
import {utilsService} from "../services/utils-service";

const Seemore = ({ white, content, rotated, external }) => (
  <div className={ white ? "seemore white": "seemore" }>
     <span className="dash"></span>
     {external ? (
       <a
        href={content.link}
        target="_blank"
        rel="noopener noreferrer"
      >
         <h2 dangerouslySetInnerHTML={{__html: content.textLink}} />
         <MnlArrow color={ white ? "#FFFFFF" : "" } rotated />
       </a>
     ) : (
       <Link to={`${utilsService.generatePrefix(content.link.locale)}/${content.link.link}`}>
         <h2 dangerouslySetInnerHTML={{__html: content.textLink}} />
         <MnlArrow color={ white ? "#FFFFFF" : "" } rotated />
       </Link>
     )}
   </div>
)

export default Seemore
