import React from 'react'
import Fade from 'react-reveal/Fade'

const HowMethod = ({ content }) => (
  <div id="how__methods" className="how__method row">
    <div className="row align-items-center
                    col-sm-12
                    col-md-4
                    col-lg-7">
      <div className="col-lg-6">
        <Fade left>
          <h2 dangerouslySetInnerHTML={{__html: content.step}} />
        </Fade>
      </div>
      <div className="icon col-lg-6">
        <Fade left>
          <img src={content.icon.url} alt={content.title} />
        </Fade>
      </div>
    </div>
    <div className="align-items-center
                    col-sm-12
                    col-md-8
                    col-lg-5">
      <Fade right>
        <h1 className="white"
            dangerouslySetInnerHTML={{__html: content.title}} />
        <section className="paragraph white">
          <span dangerouslySetInnerHTML={{__html: content.paragraph}} />
        </section>
      </Fade>
    </div>
  </div>
)

export default HowMethod
