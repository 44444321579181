import React from 'react'

// Components
import Seemore from '../components/seemore'


const Next = ({ yellow, content }) => (
  <div className="next__wrapper
                  no-gutters
                  no-padding
                  col-sm-12
                  col-lg-6">
    <div className={ yellow ? "next yellow" : "next" }>
      <h3 dangerouslySetInnerHTML={{__html: content.title}} />
      <Seemore content={content} rotated />
    </div>
  </div>
)

export default Next
