import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from "gatsby"

function Seo({ content, lang, publication, slug, keywords }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              siteUrl
            }
          }
    
          datoCmsSite {
            globalSeo {
              facebookPageUrl
              fallbackSeo {
                description
                title
                twitterCard
                image {
                  url
                  height
                  width
                }
              }
              twitterAccount
              siteName
            }
          }
        }
      `}
      render={({ datoCmsSite, site }) => {
        // Get meta from page, or get global fallback
        const metaTitle = content?.title || datoCmsSite.globalSeo.fallbackSeo.title
        const metaDescription = content?.description || datoCmsSite.globalSeo.fallbackSeo.description
        const metaImage = content?.image || datoCmsSite.globalSeo.fallbackSeo.image
        const metaTwitterCard = content?.twitterCard || datoCmsSite.globalSeo.fallbackSeo.twitterCard

        // Structured Data
        const SDataSchema = {
          '@context': 'https://schema.org/',
          '@type': 'WebPage',
          name: metaTitle,
          inLanguage: lang,
          headline: metaTitle,
          description: metaDescription,
          url: `${site.siteMetadata.siteUrl}/${lang !== 'en' ? `${lang}/` : ''}${slug ? slug : ''}`,
          copyrightYear: () => {
            const d = new Date()
            return d.getFullYear()
          },
          copyrightHolder: {
            '@type': 'Organization',
            name: datoCmsSite.globalSeo.siteName,
          },
          author: {
            '@type': 'Organization',
            name: datoCmsSite.globalSeo.siteName,
          },
          creator: {
            '@type': 'Organization',
            name: datoCmsSite.globalSeo.siteName,
          },
          publisher: {
            '@type': 'Organization',
            name: datoCmsSite.globalSeo.siteName,
          },
          image: {
            '@type': 'ImageObject',
            url: metaImage?.url,
          },
          datePublished: publication?.firstPublishedAt || publication?.publishedAt,
          dateModified: publication?.updatedAt,
        }

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={metaTitle}
            titleTemplate={'%s'}
            link={[
              { rel: 'canonical', href: `/${slug}` }
            ]}
            meta={[
              {
                name: 'application-name',
                content: datoCmsSite.globalSeo.siteName,
              },
              { name: 'publisher', content: 'Minale Design Strategy' },
              { name: 'author', content: 'Reveal Studio' },
              {
                name: 'description',
                content: metaDescription,
              },
              { name: 'keywords', content: keywords || 'Minale Design Strategy' },
              {
                name: 'og:site_name',
                content: datoCmsSite.globalSeo.siteName,
              },
              {
                property: 'og:title',
                content: metaTitle,
              },
              {
                property: 'og:description',
                content: metaDescription,
              },
              {
                property: 'og:image',
                content: metaImage?.url,
              },
              {
                property: 'og:image:height',
                content: `${metaImage?.height ? metaImage.height : ''}`,
              },
              {
                property: 'og:image:width',
                content: `${metaImage?.width ? metaImage.width : ''}`,
              },
              {
                property: 'og:type',
                content: 'website',
              },
              {
                name: 'og:url',
                content: `${site.siteMetadata.siteUrl}/${lang !== 'en' ? `${lang}/` : ''}${slug ? slug : ''}`,
              },
              {
                name: 'twitter:card',
                content: metaTwitterCard,
              },
              {
                name: 'twitter:creator',
                content: datoCmsSite.globalSeo.twitterAccount,
              },
              {
                name: 'twitter:title',
                content: metaTitle,
              },
              {
                name: 'twitter:description',
                content: metaDescription,
              },
              { name: 'twitter:image:src', content: metaImage?.url },
              { name: 'format-detection', content: 'telephone=no'},
            ]}
          >
            <script type='application/ld+json'>{JSON.stringify(SDataSchema)}</script>
          </Helmet>
        )
      }}
    />
  )
}

export default Seo
