import React from 'react'
import { Scrollchor } from 'react-scrollchor';

// Components
import Wrapper from '../wrapper'

// Assets
import MnlArrow from '../../assets/images/MnlArrow'

const HowHero = ({ content }) => (
  <Wrapper grid>
    <div className="how__hero row no-gutters">
      <h1 dangerouslySetInnerHTML={{__html: content.headline}} />
      <div className="paragraph"
           dangerouslySetInnerHTML={{__html: content.paragraph}} />
        <div className="seemore">
          <Scrollchor animate={{offset: 20, duration: 400 }} to="#how__methods">
          <span className="dash"></span>
            <h2 dangerouslySetInnerHTML={{__html: content.textLink}} />
            <MnlArrow rotate={0} />
          </Scrollchor>
        </div>
    </div>
  </Wrapper>
)

export default HowHero
